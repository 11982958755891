import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import TextEditor from "../../components/Editors/TextEditor";
import ImageEditor from "../../components/Editors/ImageEditor";
import { LoadingCircle } from "app/components/UI/Loader";
import GeneralServices from "app/apis/General/GeneralServices";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import OurVisionMissionServices from "app/apis/OurVisionMission/OurVisionMissionServices";

function OurVision() {
  const [mainBanner, setMainBanner] = useState("");
  const [sectionBanner, setSectionBanner] = useState("");
  const [ourVisionContent, setOurVisionContent] = useState("");
  const [ourMissionContent, setOurMissionContent] = useState("");
  const [loader, setLoader] = useState(true);

  const submitBanner = async (route, data, oldData) => {
    try {
      let formData = new FormData();
      {
        oldData?.id && formData.append("id", oldData?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (oldData) {
        result = await GeneralServices.updateBanner(route, formData);
      } else {
        result = await GeneralServices.createBanner(route, formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ submitBanner ~ error:", error);
    }
  };

  const handleOurVisisonContent = async (data) => {
    try {
      let formData = new FormData();
      {
        ourVisionContent?.id && formData.append("id", ourVisionContent?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (ourVisionContent?.id) {
        result = await OurVisionMissionServices.updateOurVision(formData);
      } else {
        result = await OurVisionMissionServices.createOurVision(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleOurVisisonContent ~ error:", error);
    }
  };
  const handleOurMissionContent = async (data) => {
    try {
      let formData = new FormData();
      {
        ourMissionContent?.id && formData.append("id", ourMissionContent?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (ourMissionContent?.id) {
        result = await OurVisionMissionServices.updateOurMission(formData);
      } else {
        result = await OurVisionMissionServices.createOurMission(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleOurMissionContent ~ error:", error);
    }
  };

  const getAboutBanner = async () => {
    try {
      let result = await GeneralServices.getBanner("VisionBanner");
      let result2 = await GeneralServices.getBanner("VisionFirstBanner");
      setMainBanner(result?.data[0]);
      setSectionBanner(result2?.data[0]);
    } catch (error) {
      console.log("🚀 ~ getAboutBanner ~ error:", error);
    }
  };

  const getOurVision = async () => {
    try {
      let { data } = await OurVisionMissionServices.getOurVision();
      setOurVisionContent(data[0]);
    } catch (error) {
      console.log("🚀 ~ getOurVision ~ error:", error);
    }
  };
  const getOurMission = async () => {
    try {
      let { data } = await OurVisionMissionServices.getOurMission();
      setOurMissionContent(data[0]);
    } catch (error) {
      console.log("🚀 ~ getOurVision ~ error:", error);
    }
  };

  const getAllData = async () => {
    try {
      await getAboutBanner();
      await getOurVision();
      await getOurMission();
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);

  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Our vision and mission management
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Our vision and mission management" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"Banner Image"}
              handleData={(e) => submitBanner("VisionBanner", e, mainBanner)}
              uploadedImage={mainBanner}
            />
            <TextEditor
              title="Our Vision"
              characterLimit={450}
              data={ourVisionContent}
              handleData={(e) => handleOurVisisonContent(e)}
            />
            <ImageEditor
              title={"Section Image"}
              handleData={(e) =>
                submitBanner("VisionFirstBanner", e, sectionBanner)
              }
              uploadedImage={sectionBanner}
            />
            <TextEditor
              title="Our Mission"
              characterLimit={560}
              data={ourMissionContent}
              handleData={(e) => handleOurMissionContent(e)}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default OurVision;
